.component-silent-notifications {
  position: absolute;
  bottom: 0;
  right: 0;
  max-width: 400px;
  overflow: hidden;
}

.component-silent-notifications[data-hidden='true'] {
  display: none;
}

.component-silent-notifications > div {
  background-color: #32b94090;
  color: rgba(255, 255, 255, 1);
  border-radius: 0.25rem;
  box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.25);
  transition: all 0.5s;
  margin: 1rem;
  display: flex;

  border-radius: 0.5rem 0.5rem 0 0;
  margin-bottom: 0;
  overflow: hidden;
}

.component-silent-notifications > div[data-hidden='true'] {
  margin-bottom: -100%;
  opacity: 0;
}

.component-silent-notifications > div[data-status='warning'] {
  background-color: #ab8346;
}

.component-silent-notifications > div[data-status='success'] {
  background-color: #2f999f;
}

.component-silent-notifications > div[data-status='error'] {
  background-color: #ab4646;
}

.component-silent-notifications > div .icon {
  padding: 1rem;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.15);
}

.component-silent-notifications > div .icon span {
  border-radius: 50%;
  height: 20px;
  width: 20px;
  display: block;
  text-indent: -9999px;
  background-image: url(http://simpleicon.com/wp-content/uploads/warning.png);
  background-size: contain;
  filter: invert(100%);
}

.component-silent-notifications > div p {
  margin: 0;
  font-size: 1rem;
  color: #fff;
  padding: 1rem;
  display: flex;
  align-items: center;
}
