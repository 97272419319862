.layout-private {
  height: 100%;

  background-color: #eee;
}

.layout-private > div.header {
  background-color: #2f999f;
  heigdht: 50px;
}

.layout-private .flex {
  display: flex;
}

.layout-private .flex > div.menu {
  background-color: #2f394c;
  color: #fff;
}
.layout-private img {
  display: block;
}
.layout-private .flex > div.menu > div {
  width: 271px;
  background-color: #2f394c;
  position: relative;
}

.layout-private .flex > div.menu > div > div {
  width: 271px;
  positdion: fixed;
  top: 0;
}

.layout-private .flex > div.content {
  padding: 2rem;
  width: 100%;
}
