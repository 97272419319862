.component-password {
  border-radius: 0.25rem;
  overflow: hidden;
  position: relative;
}

.component-password label {
  position: absolute;
  left: 0;
  padding: 0.5rem 0 0 1rem;
  font-size: 0.75rem;
}

.component-password[data-filled='false'] label {
  padding: 1rem;

  font-size: 1.25rem;
  cursor: text;
}

.component-password input {
  padding: 1rem;
}

.component-password[data-label='true'][data-filled='true'] input {
  padding: 1.5rem 1rem 0.5rem 1rem;
}

[data-error='true'] > div:not(.strength-bar) {
  border: 1px solid #ab465d;
}

.error {
  background-color: #ab465d;
  color: #fff;
  font-size: 0.75rem;
  padding: 0.25rem 1rem;
  font-style: italic;
}

.component-password .icon {
  position: absolute;
  color: #fff;
  /* z-index: 100; */
  right: 0;
  top: 0;
  padding: 1rem;
  font-size: 1.25rem;
}

.component-password .icon svg {
  width: 1.25rem;
}

.strength-bar {
  overflow: hidden;

  border-radius: 0.25rem;

  posdition: absolute;
  todp: 3.5rem;
  width: 100%;
  paddding: 0 1rem;
}

.strength-bar > div {
  background-color: rgba(0, 0, 0, 0.5);
  overflow: hidden;

  border-radius: 0.25rem;
}

.strength-bar > div > div {
  heght: 0.5rem;
  border-radius: 0.25rem;
  background: rgb(186, 37, 37);
  background: linear-gradient(
    90deg,
    rgba(186, 37, 37, 1) 0%,
    rgba(208, 135, 46, 1) 25%,
    rgba(53, 159, 23, 1) 100%
  );
  opacity: 0.75;
  width: 75%;
}
