.component-link {
  display: inline-block;
}

.component-link > a {
  display: flex;
}

.component-link > a > .icon {
  padding-right: 0.25rem;
}
