.component-overlay {
  background-color: #00000080;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: fixed;
  display: flex;
  align-items: center;
}

.component-overlay[data-hidden='true'] {
  display: none;
}

.component-overlay span {
  display: block;
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  border: 0.25rem solid #fff;
  border-top-color: transparent;
  margin: 0 auto;
  animation: spinner 1s linear infinite;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
