@import '@sass/constants.scss';

:root {
  --font-size: 13px;
}

@media (min-width: 600px) {
  :root {
    --font-size: 14px;
  }
}

@media (min-width: 700px) {
  :root {
    --font-size: 15px;
  }
}

@media (min-width: 800px) {
  :root {
    --font-size: 16px;
  }
}

html {
  height: 100%;
  font-size: var(--font-size);
}

body {
  margin: 0;
  font-family: 'Quicksand', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  height: 100%;
}

* {
  box-sizing: border-box;
}

input {
  border: none;
  width: 100%;

  border-radius: 0.25rem;

  background-color: rgba(0, 0, 0, 0.25);
  box-shadow: inset 0 0 0.25rem rgba(0, 0, 0, 0.05);
  font-size: 1.25rem;
  outline: none;
  color: #fff;
  font-family: 'Quicksand';
}

a.btn {
  background-color: #5a9fb7;
  margin-bottom: 1rem;
  display: block;
  border-radius: 0.5rem;
  padding: 1rem;
  color: #fff;
  text-decoration: none;
  font-weight: bold;
}

a.otro {
  color: rgba(255, 255, 255, 0.5);
  text-decoration: none;
}

pd {
  color: #ffffff60;
  font-size: 1.5rem;
  margin: 0 0 1rem 0;
}

.strong {
  height: 10px;
  background-color: #2d384c;
  overflow: hidden;
  border-radius: 0.25rem;
  margin: 0 0 1rem 0;
  padding: 2px;
}

.strong > div {
  height: 100%;
  width: 33%;
  background-color: #a24a4a;
  border-radius: 0.25rem;
}

.bottom fordm > *:not(:last-child) {
  margin-bottom: 1rem;
}

.modal {
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  display: flex;

  align-items: center;
  justify-content: center;

  display: none;
}

.modal > div {
  background-color: #fff;

  border-radius: 0.5rem;
  width: 700px;
  max-width: 90%;
}

.modal > div .content {
  padding: 2rem;

  text-align: center;
  color: rgba(0, 0, 0, 0.75);
}

.modal > div span {
  font-weight: bold;
  font-size: 1.5rem;
}

.modal p {
  color: rgba(0, 0, 0, 0.75);
  font-size: 1.25rem;
  margin-top: 1rem;
  padding: 0;
  margin-bottom: 0;
}

.layout-container {
  height: 100%;
}
