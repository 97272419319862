.component-input {
  border-radius: 0.25rem;
  overflow: hidden;
  position: relative;
}

.component-input label {
  position: absolute;
  left: 0;
  padding: 0.5rem 0 0 1rem;
  font-size: 0.75rem;
}

.component-input[data-filled='false'] label {
  padding: 1rem;
  font-size: 1.25rem;
  cursor: text;
}

.component-input input {
  padding: 1rem;
}

.component-input[data-label='true'][data-filled='true'] input {
  padding: 1.5rem 1rem 0.5rem 1rem;
}

[data-error='true'] > div:not(.strength-bar) {
  border: 1px solid #ab465d;
}

.error {
  background-color: #ab465d;
  color: #fff;
  font-size: 0.75rem;
  padding: 0.25rem 1rem;
  font-style: italic;
}
