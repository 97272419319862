@import '@sass/constants.scss';

:root {
  --block-padding: 1.5rem;
  --p-font-size: 1.25rem;
  --title-font-size: 1rem;
}

@media (min-width: 700px) {
  :root {
    --block-padding: 1.75rem;
    --p-font-size: 1.5rem;
    --title-font-size: 1.25rem;
  }
}

@media (min-width: 800px) {
  :root {
    --block-padding: 2rem;
  }
}

.layout-public {
  background: $public-bg-secondary;
  background: linear-gradient(
    180deg,
    $public-bg-primary 50%,
    $public-bg-secondary 50%
  );
  min-height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.layout-public-top {
  text-align: center;
  padding: var(--block-padding);
  &__language-button {
    position: absolute;
    top: 0;
    right: 1rem;
    background-color: rgba(0, 0, 0, 0.15);
    a {
      text-transform: uppercase;
      color: rgb(255 255 255 / 75%);
      font-size: 0.75rem;
      padding: 0.5rem 1rem;
      text-decoration: none;
    }
  }
  figure {
    margin: 0;
  }
}

.layout-public-bottom {
  text-align: center;
  background-color: $public-bg-secondary;
  color: rgba(255, 255, 255, 0.75);
  &__title {
    background-color: rgba(0, 0, 0, 0.1);
    padding: 0.5rem;
    h1 {
      margin: 0;
      font-size: var(--title-font-size);
      font-weight: normal;
      text-transform: uppercase;
    }
  }
  &__content {
    width: 100%;
    max-width: 700px;
    margin: 0 auto;
    padding: var(--block-padding);
    [data-recourse='page'] > p,
    [data-recourse='step'] > p {
      margin: 0;
      font-size: var(--p-font-size);
    }
    a {
      color: rgba(255, 255, 255, 0.5);
      text-decoration: none;
    }
    .component-expandable a {
      color: $public-bg-primary;
    }
    [data-recourse='page'] > *:not(:last-child),
    [data-recourse='step'] > *:not(:last-child),
    .component-multistep > *:not(:last-child),
    form > *:not(:last-child),
    form > .buttons > *:not(:last-child) {
      margin-bottom: 1rem;
    }
  }
}
