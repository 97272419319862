.component-expandable {
}

.component-expandable .header {
  display: flex;
  background-color: rgba(0, 0, 0, 0.25);
  align-items: center;
  cursor: pointer;
  border-left: 0.25rem solid #2f999f;
}

.component-expandable .header > div {
  padding: 1rem;
}

.component-expandable .header > div:not(.icon) {
  width: 100%;
  text-align: left;
}

.component-expandable .header > div:not(.icon) .title {
  font-size: 1.25rem;
  color: #fff;
}

.component-expandable .header > div:not(.icon) .subtitle {
  font-size: 0.75rem;
  margin-top: 0.25rem;
  font-style: italic;
}

.component-expandable .header > div.icon {
  font-size: 1.25rem;
}

.component-expandable .content {
  overflow: hidden;
}

.component-expandable .content > div {
  margin-top: -100%;
}

.component-expandable[data-expanded='true'] .content > div {
  margin-top: 0;
  transition: margin-top 0.25s;
}

.component-expandable[data-expanded='false'] .content > div {
  transition: margin-top 1s;
}

.component-expandable .content > div > p {
  display: block;
  background-color: rgba(0, 0, 0, 0.25);
  margin: 0.5rem 0 0 0;
  padding: 1rem;
  text-align: left;
}
