.component-multistep-progress {
}

.component-multistep-progress > div {
  margin-left: -0.5rem;
  display: flex;
}

.component-multistep-progress > div .step {
  flex: 1;
  padding-left: 0.5rem;
}

.component-multistep-progress > div .step > div:nth-child(1) {
  height: 2rem;
  position: relative;
}

.component-multistep-progress .bar {
  padding: 10px 0;
}

.component-multistep-progress .bar span {
  display: block;
  height: 10px;
}

.component-multistep-progress > div .step:first-child .bar span {
  border-radius: 0.5rem 0 0 0.5rem;
}

.component-multistep-progress > div .step:last-child .bar span {
  border-radius: 0 0.5rem 0.5rem 0;
}

.component-multistep-progress .badget {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
}

.component-multistep-progress .badget span {
  display: block;
  border-radius: 50%;
  height: 2rem;
  width: 2rem;
  margin: 0 auto;
  line-height: 2rem;
}

.component-multistep-progress [data-current='true'] .badget span {
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
  transform: scale(1);
  animation: badget-pulse 2s infinite;
}

@keyframes badget-pulse {
  0% {
    transform: scale(0.75);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.75);
  }
  75% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }
  100% {
    transform: scale(0.75);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}

.component-multistep-progress .step > div span {
  font-weight: bold;
}

.component-multistep-progress .step .title {
  margin-top: 0.5rem;
  color: #fff;
  font-size: 0.85rem;
  padding: 0 1rem;
}

/* layout public */

.layout-public .component-multistep-progress .step {
  opacity: 0.25;
}

.layout-public .component-multistep-progress .step[data-done='true'] {
  opacity: 1;
}

.layout-public .component-multistep-progress .step > div span {
  background-color: #fff;
  color: #000;
}

.layout-public
  .component-multistep-progress
  .step[data-done='true']
  > div
  span {
  background-color: #2f999f;
  color: #fff;
}

/* layout private */
