.component-button {
  display: block;
}

.component-button > * {
  background-color: #2f999f;
  font-family: 'Quicksand';
  font-size: 1.25rem;

  border-radius: 0.25rem;

  color: #fff;
  text-decoration: none;
  fbox-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.15);
  fborder: 0.25rem solid rgba(255, 255, 255, 0.05);
  cursor: pointer;
  outline: none;
}

.component-button > *[data-fluid='true'] {
  rdisplay: block;
  width: 100%;
}

.component-button > *[data-disabled='true'] {
  opacity: 0.25;
  cursor: auto;
  box-shadow: unset;
}

.component-button > *[data-type='cancel'] {
  background-color: #ab465d;
}

.component-button a {
  display: flex;
  width: 100%;
  color: #fff;
}

.component-button .icon {
  padding: 0.75rem;
  background-color: rgba(0, 0, 0, 0.1);
}

.component-button .icon .component-icon {
  min-width: 1.25rem;
}

.component-button .text {
  padding: 0.75rem 1.5rem;
  position: relative;
  width: 100%;
}

.component-button .text::before {
  content: '';
  left: 0;
  top: 0;
  position: absolute;
  height: 100%;
  width: 0;
  background-color: rgb(0 0 0 / 10%);
}

@keyframes badget-pulse2 {
  0% {
    width: 0%;
  }
  50% {
    width: 100%;
  }
  100% {
    width: 0%;
  }
}

a[data-disabled='false']:hover .text::before,
a[data-disabled='false']:focus .text::before {
  animation: badget-pulse2 1s;
}
a[data-disabled='false']:hover .icon svg,
a[data-disabled='false']:focus .icon svg {
  animation: badget-pulse3 1s;
}

@keyframes badget-pulse3 {
  100% {
    transform: rotate(360deg);
  }
}

.layout-public .component-button a {
  text-transform: lowercase;
}
